.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}

.react-datepicker__header {
  background-color: unset;
  border-bottom: 0;
}

.react-datepicker__day--outside-month {
  visibility: hidden;
}

.react-datepicker__day-names {
  margin-bottom: -6px;
}

.react-datepicker__day {
  font-size: 20px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2rem;
  line-height: 2rem;
  margin: 5px;
}

.react-datepicker__day-name {
  color: #3c3c434c;
  line-height: 18px;
  font-size: 13px;
}

.react-datepicker__day,
.react-datepicker__day:hover,
.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
  border-radius: 1rem;
}

.react-datepicker__day--today {
  font-weight: unset;
  position: relative;
}

.react-datepicker__day--today::after {
  position: absolute;
  content: " ";
  width: 8px;
  height: 8px;
  background-color: #ff3b30;
  border-radius: 100%;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -3px;
}

.react-datepicker__day--selected {
  background-color: #007aff;
}

.react-datepicker__month {
  margin-bottom: 12px;
}

.react-datepicker-popper {
  z-index: 9999;
  position: fixed !important;
}

.react-datepicker__input-time-container {
  float: unset;
  margin: 0;
  text-align: unset;
}

.react-datepicker__input-time-container
  .react-datepicker-time__input-container {
  width: 100%;
}

.react-datepicker__input-time-container
  .react-datepicker-time__input-container
  .react-datepicker-time__input {
  margin: 0;
  width: 100%;
}

.react-datepicker__input-time-container
  .react-datepicker-time__input-container
  .react-datepicker-time__input
  input {
  width: 30px;
}
